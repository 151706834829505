@import 'colors';
@import 'fonts';
@import 'dkuvariables';
@import "layout-variables";

.mat-select-value {
    font-family: 'SourceSansPro';
    font-size  : 13px;
    text-align : right;
}

.mat-option {
    font-family: 'SourceSansPro';
}

.mat-select-panel {
    border-radius: 0 !important; //necessary to overload inline style added by angular;
    max-height   : 300px !important; //necessary to overload inline style added by angular;

    &:not([class*=mat-elevation-z]) {
        box-shadow: 2px 2px 8px 1px rgba(34, 34, 34, 0.2);
    }
}

.mat-select-panel .mat-option.dku-mat-option--described {
    height : auto;
    padding: 10px;

    &:not(.mat-selected):hover {
        background-color: @line-hover;
    }

    &.mat-selected {
        background-color: @selected-item-color;

        &:hover {
            background-color: @selected-hover-color;
        }
    }

    &:not(:first-child) {
        border-top: 1px solid @border-color;
    }

    .mat-option-text {
        line-height: 20px;
    }

    &[aria-disabled=true] {
        color         : @text-weak;
        pointer-events: none;
        opacity       : 0.5;
    }
}

.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
    background-color: @selected-hover-color;
}

.mat-autocomplete-panel {
    border-radius: 0 none;
}

.mat-tab-group.mat-primary .mat-ink-bar {
    background-color: @digital-blue-base;
}

.mat-tab-label {
    min-width: 120px;
}

.dku-mat-select-panel--described {
    min-width: none !important; //necessary to overload inline style added by angular
    max-width: none !important; //necessary to overload inline style added by angular;
    width    : 500px !important; //necessary to overload inline style added by angular;
}

.mat-option__main-label {
    font-weight: @font-weight-semi-bold;
    font-size  : 13px;
    line-height: 23px;
}

.mat-option__description {
    font-size  : 12px;
    color      : @text-weak;
    line-height: 17px;
}

.mat-menu-item,
.mat-option {
    height     : 32px;
    line-height: 32px;
    padding    : 0 8px;
    font-size  : 13px;
}

.mat-menu-content:not(:empty) {
    padding: 0;
}

.mat-menu-item--selected {
    font-weight: @font-weight-semi-bold;
}

.mat-menu-panel {
    border-radius: 0px;
    min-height   : 32px;
}

.mat-tooltip {
    white-space: pre-line;
}

.overlay-no-pointer-events {
    pointer-events: none;
}

.mat-form-field-appearance-legacy {
    .mat-form-field-wrapper {
        padding: 0;
    }

    .mat-form-field-underline {
        display: none;
    }

    .mat-form-field-infix {
        border : none;
        padding: 0;
        width  : 206px;
    }

    .mat-form-field-label-wrapper {
        display: none;
    }
}

// force placeholders
.mat-form-field-hide-placeholder .mat-input-element {

    &::-webkit-input-placeholder,
    &::placeholder {
        color                  : @grey-lighten-4  !important; // important to override material's important
        -webkit-text-fill-color: @grey-lighten-4;
    }
}

div.cdk-overlay-container {
    z-index: 4000; // to appear over modals too
}

// BEGIN CUSTOMIZE <mat-select>

.mat-select-search-inside-mat-option .mat-select-search-input {
    line-height  : 24px !important;
    height       : 24px !important;
    margin-bottom: 0 !important;
}

.mat-select-panel .mat-option {
    line-height: 24px;
    height     : 24px;
}

.mat-select-panel .mat-optgroup-label {
    font-size: 15px;
    line-height: 20px;
    height: 20px;
    padding: @spacing 0 @spacing @spacing-half;
    margin: 0;
    color: @grey-lighten-1;
    font-weight: @font-weight-bold;
    border-top: 1px @light-border-color solid;
    border-bottom: 1px @light-border-color solid;
    &~.mat-option:not(:first-of-type) {
        border-top: 1px @light-border-color solid;
    }

    &~.mat-option {
        .mat-pseudo-checkbox {
            border-color: transparent;

            &::after {
                color: black;
            }
        }

        .mat-pseudo-checkbox-checked {
            background: transparent;
        }
    }


}

.mat-select-trigger {
    height     : 24px;
    border     : 1px solid @grey-lighten-6;
    line-height: 24px;
}

.mat-select-value {
    text-align  : left;
    padding-left: 5px;
}

.mat-form-field-hide-placeholder .mat-select-placeholder {
    color                  : @grey-lighten-1;
    -webkit-text-fill-color: @grey-lighten-1;
}

.mat-option.contains-mat-select-search.mat-select-search-no-entries-found {
    height: 60px !important;
}

// END CUSTOMIZE <mat-select>

// BEGIN CUSTOMIZE <mat-table>

.dku-mat-table.mat-table {
    font-family    : SourceSansPro;
    font-weight    : @font-weight-normal;
    border-left    : 1px solid @grey-lighten-6;
    border-right   : 1px solid @grey-lighten-6;
    box-shadow     : @dku-shadow-elevation-1;
    /* For sticky borders on headers */
    border-collapse: separate;
    border-spacing : 0;

    input[type="checkbox"] {
        margin: 0;
    }

    .mat-cell,
    .mat-header-cell {
        word-wrap     : initial;
        display       : table-cell;
        padding       : 8px;
        max-width     : 200px;
        vertical-align: middle;
        color         : #000000;
        font-size     : 13px;
        border-top : none;
        border-bottom    : 1px solid @grey-lighten-6;
    }

    .mat-cell {
        font-weight: @font-weight-normal;
        line-height: 20px;
    }

    .mat-header-cell {
        font-weight: @font-weight-bold;
        line-height: 20px;
        background-color: @white-base;
    }

    .mat-row,
    .mat-header-row {
        display: table-row;
        height : 29px;
    }

    .mat-header-row:first-child .mat-header-cell {
        border-top: 1px solid @grey-lighten-6;
    }

    .mat-row:last-child .mat-cell {
        border-bottom: none;
    }

    .mat-sort-header-arrow[style] {

        // Hide default arrow stem
        .mat-sort-header-stem {
            display: none;
        }

        .mat-sort-header-indicator {
            opacity               : 0.5;
            width                 : auto;
            height                : auto;
            line-height           : normal;
            vertical-align        : baseline;
            background-image      : none;
            background-position   : 0% 0%;
            background-repeat     : repeat;
            margin-top            : 0;
            font-family           : FontAwesome;
            font-weight           : normal;
            font-style            : normal;
            text-decoration       : inherit;
            -webkit-font-smoothing: antialiased;
            font-size             : 13px;
            color                 : #333333;

            // Hide default arrow as its composed of left, right and middle
            .mat-sort-header-pointer-left,
            .mat-sort-header-pointer-right,
            .mat-sort-header-pointer-middle {
                display: none;
            }
        }
    }

    // Custom ascending icon equivalent to .icon-sort-by-attributes
    [aria-sort="ascending"] {
        .mat-sort-header-arrow {
            .mat-sort-header-indicator {
                &::before {
                    content : "\f0d8";
                    top     : -3px;
                    position: absolute;
                }
            }
        }
    }

    // Custom descending icon equivalent to .icon-sort-by-attributes-alt
    [aria-sort="descending"] {
        .mat-sort-header-arrow {
            .mat-sort-header-indicator {
                &::before {
                    content : "\f0d7";
                    top     : -8px;
                    position: absolute;
                }
            }
        }
    }
}

// END CUSTOMIZE <mat-table>
